import React from "react";
import Inicio from "../../components/Inicio/Inicio";



const Landing = () => {
    return(
        <div>
            <Inicio />
        </div>
    )
}
export default Landing;